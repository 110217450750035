import React, { useEffect } from "react";
import CalenderScheduler from "./CalenderScheduler";
import Profile from "./Profile";
import companylogo from "../images/companylogo.png";
import {
  Grid,
  TextField,
  Button,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import axios from "axios";
import bgImage from "../../src/images/bg-img.jpg";
import BackgroundImageOnLoad from "background-image-on-load";
import Moment from "react-moment";
import moment from "moment";
import PreloadImage from "react-preload-image";
// import { TextField, Button, InputLabel, Select } from '@material-ui/core';

function Home(props) {
  const [inputValue, setInputValue] = React.useState(
    "Understanding Document Digitization"
  );
  const [whitepapers, setWhitepapers] = React.useState([]);
  const [whitepapersImage, setWhitepapersImage] = React.useState(
    "Document-Digitalization.png"
  );
  const [whitepapersPDF, setWhitepapersPDF] = React.useState(
    "Digital_Services _Conversion _iBridge_Feb_2020.pdf"
  );
  const [inputComment, setInputComment] = React.useState(
    "Understanding Document Digitization"
  );

  const MeetingData = [
    {
      id: "1",
      tittle: "Let's talk.",
      time: "(15 Min)",
      url: "https://calendly.com/deshurs/thinking-innovating-solving",
      // text: "Welcome! Let's use this time effectively. Let's talk about your business, what is important to you, and what you would like to achieve. We will find more time to dig a little deeper."
    },
    {
      id: "2",
      tittle: "Let's think together.",
      time: "(30 Min)",
      url: "https://calendly.com/deshurs/thinking--solving-building",
      // text: "Welcome! Let's use this time effectively.Let's talk about your business, what is important to you,and what you would like to achieve. We will find a solution together.",
    },
    {
      id: "3",
      tittle: "Let's build together.",
      time: "(60 Min)",
      url: "https://calendly.com/deshurs/thinking-innovating-executing",
      // text: "Welcome! Let's use this time effectively. Let's talk about your business, what is important to you, and what you would like to achieve.We will make things happen.",
    },
  ];

  const handleContentImage = (id, item) => {
    if (id === item.id) {
      setWhitepapersImage(item.image_name);
      setWhitepapersPDF(item.file_name);
      setInputValue(item.title);
      setInputComment(item.comment);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const result = await axios(
        process.env.React_App_Backend_Url + "get_whitepaper_data.php"
      ); /*/api/pdf_files.json*/ /*  /upload/ */

      setWhitepapers(result.data.whitepapers);

      const bgimg = [bgImage];
      sessionStorage.setItem("bgimg", bgImage);
    };
    fetchData();
  }, []);

  return (
    <div>
      {console.log(whitepapers)}
      <Grid container>
        {/* Header */}
        <Grid container className="header">
          <Grid item xs={8} sm={8} md={8}>
            <img src={companylogo} alt="company-logo" className="logo" />
          </Grid>
          <Grid item xs={4} sm={4} md={4}>
            <div className="c-moto">
              <span>We Listen.</span>
              <span>We Learn.</span>
              <span>We Execute.</span>
              <sup className="f-sm">SM</sup>
            </div>
          </Grid>
        </Grid>

        {/* Section */}
        <Grid item xs={12} sm={12} md={6}>
          <Profile />
        </Grid>

        <Grid item xs={12} sm={12} md={6} className="bgImageWrap">
          {/* <PreloadImage
            item
            xs={12}
            sm={12}
            md={6}
            className="bgImage"
            src="/images/bg-img.jpg"
            lazy
          /> */}
          {/* <CalenderScheduler /> */}
          <div className="meetingContainer">
            {MeetingData.length > 0 &&
              MeetingData.map((data, index) => {
                return (
                  <div className="bookMeeting" key={index}>
                    <div className="bookMeetingTittle">
                      <div style={{ textAlign: "initial" }}>
                        <span className="tittleStyle">{data.tittle}</span>
                        <br />
                        <span className="timeStyle">{data.time}</span>
                      </div>
                      {/* <div className="textStyle">{data.text}</div> */}
                    </div>
                    <div className="bookMeetingImage">
                      <a
                        className="atag"
                        href={data.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span className="bookMeetingFont">Book a meeting</span>
                      </a>
                    </div>
                  </div>
                );
              })}
          </div>

          {/* Whitepaper */}
          <div className="outer-block">
            <Grid container className="whitepaperBox">
              <Grid item xs={12} sm={12} md={12}>
                <div className="p-info-block mt-2">
                  <Grid container className="" spacing={2}>
                    <Grid item xs={9} sm={9} md={9}>
                      <TextField
                        fullWidth={true}
                        label=""
                        id="fullWidth"
                        multiline
                        maxRows={2}
                        disabled
                        value={inputComment}
                      />

                      <InputLabel htmlFor="age-native-simple" className="mt-2">
                        White Paper
                      </InputLabel>
                      <Select
                        fullWidth={true}
                        value={inputValue}
                        // open={open}
                        displayEmpty
                      >
                        <MenuItem value={inputValue} className="selected">
                          {inputValue}
                        </MenuItem>

                        {whitepapers.map((item) => (
                          <MenuItem
                            value={item.id}
                            onClick={(e) => {
                              handleContentImage(item.id, item);
                            }}
                          >
                            {item.title}
                          </MenuItem>
                        ))}
                      </Select>
                      {/* <a href={`${process.env.PUBLIC_URL + whitepapersPDF}`} className="d-btn" target='_blank' download>Download</a> */}
                      <a
                        href={"/upload/" + whitepapersPDF}
                        className="d-btn"
                        target="_blank"
                        download
                        rel="noreferrer"
                      >
                        Download
                      </a>
                    </Grid>

                    <Grid item xs={3} sm={3} md={3}>
                      <div className="content-img-block">
                        {/* {whitepapersImage == null ? <Skeleton className="mt-2" variant="rect" /> : <img src={whitepapersImage} alt="content-img" />} */}
                        <img
                          src={"/images/" + whitepapersImage}
                          alt="content-img"
                        />
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            </Grid>
          </div>
        </Grid>

        {/* About */}
        <Grid container className="a-block">
          <Grid item xs={12} sm={12} md={12}>
            <p>
              For over 15 years, iBridge has helped many organizations in the
              legal, healthcare, manufacturing, utilities, financial, and
              education industries apply the latest technologies to support
              their growth and increase their profitability. With more than
              1,500 employees distributed over three continents, we have the
              resources to solve problems immediately and maintain long-term
              partnerships with our clients for their ongoing success.
            </p>
          </Grid>
        </Grid>

        {/* footer */}
        <Grid container>
          <span className="footer">
            © 2021, iBridge LLC. All rights reserved.
          </span>
        </Grid>
      </Grid>
    </div>
  );
}

export default Home;
