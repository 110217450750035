

import React, { useEffect } from 'react';
import { Grid } from '@material-ui/core';
import userprofile from "../images/user-profile-one.jpg";

import PreloadImage from 'react-preload-image';


function Profile(props) {
    return (
        <div>
            {/* Profile block */}
            <div className="p-outer-block">
                <Grid container className="p-info-block">
                    {/* <PreloadImage
                        className="u-logo"
                        src={userprofile}
                        lazy
                    /> */}
                    <div item xs={12} sm={12} md={6} className="p-img">
                        
                        <img src={userprofile} alt="user-logo" className="u-logo" />
                    </div>

                    <p>Hi, I am Desh, it is good to meet you. If you have reached this page, then either someone has recommended you speak with me, or we have made some other connection. Regardless, this is a great starting place for meaningful conversations, me learning more about you and you about me. Maybe it is a project you are working on that my company iBridge can support, or we share a common interest.</p>
                    <p>I have been privileged to work with some of the best companies in the technology industry. I made great friends and was lucky to have several mentors that helped shape my 25-year corporate career. But after more than two decades of corporate America, I needed a change. My wife had a successful career in the aerospace industry, and we founded iBridge in 2004.</p>
                    <p>Our goal was to create a technology solutions company with a collaborative, entrepreneurial culture by design and encourage people to think and test ideas. 16 years and 1,500 worldwide employees later, we continue to integrate and develop new technology solutions, provide business process outsourcing services, and combine human and computer intelligence for data mining, analytics, and more. It has been a blast!</p>
                    <p>But what is your story? What projects are you working on right now?</p>
                    <p>I invite you to connect. The worst thing that can happen is we leave the conversation with a bit more knowledge or perhaps a new friend.</p>
                    <p>Sincerely,<br /> <span className="sign">Desh</span></p>
                </Grid>
            </div>
        </div>
    );
}

export default Profile;